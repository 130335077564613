  // Variant Select using Details > Summary on PDP
  class VariantDetailsSelect extends HTMLElement {
    constructor() {
      super();
      this.detailsElement = this.querySelector('details');
      this.summaryElement = this.querySelector('summary');
      this.selectedSizeSpan = this.querySelector('[id^="selected-size-"]');
      this.hiddenSelect = this.querySelector('select');
      this.optionsList = this.querySelector('ul');
      this.optionItems = this.querySelectorAll('li');
    }

    connectedCallback() {
      this.initDetailsSelect();
      this.parentVariantRadios = this.closest('variant-radios');
    }

    initDetailsSelect() {
      if (this.hiddenSelect.value) {
        this.selectedSizeSpan.textContent = this.hiddenSelect.value;
      }

      this.optionItems.forEach(option => {
        option.addEventListener('click', this.handleOptionClick.bind(this));
      });

      document.addEventListener('click', (event) => {
        if (!this.detailsElement.contains(event.target)) {
          this.detailsElement.removeAttribute('open');
        }
      });
    }

    handleOptionClick(event) {
      const option = event.currentTarget;
      if (option.getAttribute('aria-disabled') !== 'true') {
        const value = option.dataset.value;
        this.selectedSizeSpan.textContent = value;
        this.hiddenSelect.value = value;

        // Remove aria-selected from all options and set it on the clicked option
        this.optionItems.forEach(item => item.removeAttribute('aria-selected'));
        option.setAttribute('aria-selected', 'true');

        this.detailsElement.removeAttribute('open');

        // Trigger change event on hidden select
        this.hiddenSelect.dispatchEvent(new Event('change', { bubbles: true }));

        // Notify the parent VariantRadios component
        if (this.parentVariantRadios) {
          this.parentVariantRadios.onVariantChange();
        }
      }
    }
  }

  customElements.define('variant-details-select', VariantDetailsSelect);
